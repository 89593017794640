import { t } from "i18next";
import { CorePropsInterfaces, DTOs } from "src/core/Models";
import GtDialog from "./GtDialog";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import { Button, TextField } from "@mui/material";
import * as Constants from "./../../core/Constants";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/contexts/AppContext";
import { ensureEventValueIsAValidInteger, formatDate, getEngagementTotals } from "src/utils";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";

function GtSetFinalDocumentDialog({
  onConfirm,
  onDialogClose
}: CorePropsInterfaces.GtSetFinalDocumentDialogProps): JSX.Element {
  const { globalState, dispatch } = useContext(AppContext);
  const [engagementCost, setEngagementValue] = useState('');
  const [totalCalculatedCost, setTotalCalculatedCost] = useState(0);

  const [isTotalEngagementCostValid, setIsTotalEngagementCostValid] = useState(false);
  const validFromDate = !!globalState.currentConfiguration.validFromDate
    ? formatDate(
        globalState.currentConfiguration.validFromDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      )
    : null;
  const validUntilDate = !!globalState.currentConfiguration.validUntilDate
    ? formatDate(
        globalState.currentConfiguration.validUntilDate,
        Constants.HelpfulConstants.DateFormatYearMonthAndDay
      )
    : null;

  const finalDocumentDialogDTO: DTOs.GtDialogDTO = {
    data: {
      maxWidth: "sm",
      leftButtons: [],
      rightButtons: [
        <Button
          onClick={onDialogClose}
          color="tertiary"
          variant="outlined"
          key="cancelBtn"
        >
          {t("General.Cancel")}
        </Button>,
        <Button
          color="tertiary"
          variant="contained"
          key="confirmBtn"
          disabled={isTotalEngagementCostValid}
          onClick={onConfirm}
        >
          {t("General.Create")}
        </Button>,
      ],
      title: (
        <span className="gt-finalSectionDialogIcon">
          <NoteAddOutlinedIcon />
        </span>
      ),
    },
    api: {
      onClose: onDialogClose
    },
    state: {
      isOpen: true,
      isFullWidth: true,
    },
  };
  
  const updateTotalCostManually = (event:any) => {
    ensureEventValueIsAValidInteger(event);

    if(!event.target.value || Number(event.target.value) < 1)     {
      dispatch({ 
        type: Constants.AppStateActions.UpdateTotalCostManualOverride, 
        payload: null
      });
      setEngagementValue('');
      setIsTotalEngagementCostValid(true);
    } else {
      let payload: Number = Number(event.target.value);
      // if new cost is equal to total calculated cost then clean overridden value
      if(totalCalculatedCost === payload) {
        payload = null;
      }
      
      dispatch({ 
          type: Constants.AppStateActions.UpdateTotalCostManualOverride, 
          payload: payload
        });

      setEngagementValue(event.target.value);
      setIsTotalEngagementCostValid(false);
    }
  }

  function updateTotal(totalCost: Number) {
    if (totalCost === 0) {
      setEngagementValue(process.env.REACT_APP_MINIMAL_TOTAL_ENGAGEMENT_COST);
    } else {
      setEngagementValue(totalCost.toString());
    }
  }

  const resetTotalCost = (event:any) => {
    dispatch({ 
      type: Constants.AppStateActions.UpdateTotalCostManualOverride, 
      payload: null
    });
    const totalCost = getEngagementTotals(globalState);
    updateTotal(totalCost);
    setIsTotalEngagementCostValid(false);
  }

  useEffect(() => { 
    const totalCost = getEngagementTotals(globalState);
    setTotalCalculatedCost(totalCost);
    updateTotal(totalCost);
  }, []);

  const isOpenInUpsales =
    globalState.remoteData.businessOpportunityInfo.Status ===
    Constants.BusinessOpportunityStatuses.OPEN;
  return (
    <GtDialog gtDialogDTO={finalDocumentDialogDTO}>
      <article className="gt-finalSectionDialog">
        <section className="gt-finalSectionDialog__content">
          <div className="gt-finalSectionDialog__content-question">
            {t("General.CreateFinalDocumentQuestion")}
          </div>
          <div className="gt-finalSectionDialog__content-explanation">
            {t("General.CreateFinalDocumentQuestionDetail")}
          </div>
          <div className="gt-finalSectionDialog__content-explanation">
            {isOpenInUpsales ? (
              <span>
                {t("General.SumSentToUpsalesInfoPrefix")}{" "}
                <TextField
                  onChange={updateTotalCostManually}
                  className="gt-finalSectionDialog__totalcost-input"
                  type="text"
                  variant="outlined"
                  value={`${engagementCost}`}
                />
                <ReplayRoundedIcon
                onClick={resetTotalCost}
                className={`gt-dataTableCellWrapper__revertIcon gt-finalSectionDialog__reset-total-icon`} 
                 />
                <span className="gt-finalSectionDialog__sufix-text">{t("General.SumSentToUpsalesInfoSuffix")}{" "}</span>
              </span>
            ) 
            : (
              <span>{t("General.SumSentToUpsalesInfoLocked")} </span>
            )}
          </div>
          <span className="gt-finalSectionDialog__content-explanation">
            {t("General.ValidFromModalText")}
            <div className="gt-finalSectionDialog__content-explanation-validity">
              <strong>
                {validFromDate}
                {!!validUntilDate && (
                  <>
                    <span className="gt-finalSectionDialog__content-explanation-validity-dateSeparator">
                      -
                    </span>
                    {validUntilDate}
                  </>
                )}
              </strong>
            </div>
          </span>
        </section>
      </article>
    </GtDialog>
  );
}

export default GtSetFinalDocumentDialog;